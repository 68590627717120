<template>
  <div class="archive2">
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Kakimoto Logo"
          class="shrink mr-2"
          contain
          src="@/assets/favicon-196x196.png"
          transition="scale-transition"
          width="40"
        />
      </div>
      <v-toolbar-title>監視番カメラ</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="windowSize === 1">
        <v-btn
          x-small
          rounded
          color="primary"
          @click="pageSendLogin"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          最新画像に戻る
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          rounded
          color="primary"
          @click="pageSendLogin"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          最新画像に戻る
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      left
      temporary
      fixed
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title 
              @click="logoutDialog = true"
            >
              ログアウト
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    
    <v-dialog
      v-model="logoutDialog"
      persistent
      max-width="750"
    >
      <v-card>
        <v-card-title>ログアウト</v-card-title>
        <v-card-text>
          ログアウトしてよろしいでしょうか。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="logoutDialog = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="submit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>

      <v-data-iterator
        :items="latestImg"
        item-key="name"
        sort-by="showIndex"
        loading
        loading-text="データを読み込み中です。"
        locale="ja-JP"
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row
            dense
          >
            <v-col
              v-for="item in items"
              :key="item.showIndex"
              cols="12"
              xs="6"
              sm="6"
              md="4"
              lg="4"
            >
              <v-card
                outlined
                tile
              >
                <v-card-title>{{ item.deviceName }}</v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-img
                      v-bind:src="'data:image/jpeg;base64,' + item.image"
                      @click="getHighResoImage(item.deviceID, item.deviceName)"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item>

                  <v-divider></v-divider>
                  
                  <v-list-item>
                    <v-card-actions>
                      <div v-if="windowSize === 1">
                        <v-btn
                          x-small 
                          class="info"
                          @click="downloadImage(item.deviceName)"
                        >ﾀﾞｳﾝﾛｰﾄﾞ</v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          class="info"
                          @click="downloadImage(item.deviceName)"
                        >ﾀﾞｳﾝﾛｰﾄﾞ</v-btn>
                      </div>
                    </v-card-actions>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
          
      <v-footer
        app
        padless
        outlined
        fixed
      >
        <v-row
          justify="center"
          align="center"
          no-gutters
        > 
          <span class="grey--text my-2">画像表示数</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="my-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <v-pagination
            v-model="page"
            class="my-4"
            :length="numberOfPages"
            :total-visible="7"
          ></v-pagination>

        </v-row>
      </v-footer>

    </v-container>
    
    <v-dialog
      v-model="dialog"
      persistent
      max-width="750"
    >
      <v-card>
        <v-card-title>{{highResoImg.deviceName}}</v-card-title>
        <v-img
          v-bind:src="'data:image/jpeg;base64,' + highResoImg.image"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { Auth } from 'aws-amplify';
import { AmplifyConf } from '../plugins/amplifyConf';

  export default {
    name: 'Archive2',
    props: {
      selectDeviceID: String,
      startDate: String,
      startTime: String,
      endDate: String,
      endTime: String
    },

    data: () => ({
      latestImg: [],
      highResoImg: {},
      loginStatus: '',
      loading: false,
      archiveList: [],
      selectArchiveList: [],
      dialog: false,
      drawer: false,
      group: null,
      logoutDialog: false,
      windowSize: 0,
      itemsPerPageArray: [4, 8, 12],
      itemsPerPage: 4,
      page: 1,
    }),
    
    computed: {
      numberOfPages () {
        return String(Math.ceil(this.archiveList.length / this.itemsPerPage));
      },
    },

    beforeCreate: function () {
      AmplifyConf();
      Auth.currentSession()
        .then(() => {
          //console.log ('ログイン済');
        })
        .catch(() => {
          //console.log('ログイン未');
          //this.$router.push('/');
        });
    },

    created: function () {
      this.getArchiveList();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },

    watch: {
      selectArchiveList: 'getImage',
      page: 'getSelectArchiveList',
      itemsPerPage: 'getSelectArchiveList',
      group () {
        this.drawer = false;
      },
    },

    methods: {
      handleResize() {
        if (window.innerWidth < 600) {
          this.windowSize = 1;
        } else {
          this.windowSize = 0;
        }
      },
      getArchiveList() {
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        //const userID = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*LastAuthUser$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/archivelist?deviceID=' + this.selectDeviceID + '&startDate=' + this.startDate + '&startTime=' + this.startTime + '&endDate=' + this.endDate + '&endTime=' + this.endTime,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
            }}
          ).then((responce) => {
            //this.$set(this.cameraList, responce.data.body);
            this.archiveList = responce.data.body;
            const startNum = (this.page-1)*this.itemsPerPage;
            const endNum = (this.page*this.itemsPerPage)-1;
            for (const [index, item] of responce.data.body.entries()) {
              if ((index >= startNum) && (index <= endNum)) {
                this.selectArchiveList.unshift(item);
              } 
            }
          })
          .catch((e) => {
            console.log(e);
          })
      },
      getSelectArchiveList() {
        this.selectArchiveList.splice(0);
        const startNum = (this.page-1)*this.itemsPerPage;
        const endNum = (this.page*this.itemsPerPage)-1;
        for (const [index, item] of this.archiveList.entries()) {
          if ((index >= startNum) && (index <= endNum)) {
            this.selectArchiveList.unshift(item);
          } 
        }
      },
      getImage() {
        this.latestImg.splice(0);
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        for ( const key in this.selectArchiveList ) {
          const imgName = this.selectArchiveList[key]['fileName'];
          const showIndex = this.selectArchiveList[key]['showIndex'];
          const deviceName = imgName;
          this.axios.get(
            'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + this.selectDeviceID + '&imgName=archive/' + imgName + '_thumb.jpg',
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': idToken,
            }}
            ).then((responce) => {
              //this.$set(this.latestImg, responce.data.body);
              this.latestImg.push({'deviceID': this.selectDeviceID, 'deviceName': deviceName, 'image': responce.data.body, 'showIndex': showIndex});
            })
            .catch((e) => {
              console.log(e);
            })
        }
      },
      getHighResoImage(deviceID, imgName) {
        this.dialog = true
        this.highResoImg = {};
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + this.selectDeviceID + '&imgName=archive/' + imgName + '.jpg',
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
          }}
          ).then((responce) => {
            //this.$set(this.latestImg, responce.data.body);
            this.highResoImg = {'deviceID': deviceID, 'deviceName': imgName, 'image': responce.data.body};
          })
          .catch((e) => {
            console.log(e);
          })
      },
      downloadImage(imgName) {
        const AuthStorage = this.$store.getters.cognitoAuthStorage;
        const idToken = AuthStorage[Object.keys(AuthStorage).find(x => x.match(/.*idToken$/))]
        this.axios.get(
          'https://7gzqkmw383.execute-api.ap-northeast-1.amazonaws.com/pi-camera/img?deviceID=' + this.selectDeviceID + '&imgName=archive/' + imgName + '.jpg',
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': idToken,
          }}
          ).then((responce) => {
            const data = 'data:image/jpeg;base64,' + responce.data.body;
            let bin = atob(data.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }
            const blob = new Blob([buffer, buffer], { type: 'image/jpeg' })
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.download = imgName + '.jpg';
            a.href = url;
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch((e) => {
            console.log(e);
          })
      },
      pageSendLogin() {
        const v_router = this.$router;
        v_router.push('/login');
      },
      submit() {
        const v_router = this.$router;
        const v_store = this.$store;
        Auth.signOut()
          .then(() => {
            v_store.commit('refresh');
            v_router.push('/');
            //console.log('ログアウトに成功しました');
          })
          .catch((e) => {
            console.log(e);
            //console.log('ログアウトに失敗しました')
          })
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },

  }
</script>